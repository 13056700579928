<template>
  <sgv-table
    :rKey="rKey"
    :items="discountPrices"
    :headers="headers"
    :toolbars="toolbars"
    :filter.sync="filter"
    :options.sync="options"
    :rowsPerPage="rowsPerPage">

    <template slot-scope="{item, hidden}">
      <tr>
        <td v-if="hidden.name">
          <router-link
            class="text-decoration-none"
            :to="toDetail(item.id)">
            {{item.name}}
          </router-link>
          <small>
            <div v-if="item.isDefault" class="text-success">
              ค่าเริ่มต้น
            </div>
          </small>
        </td>
        <td v-if="hidden.unit">
          {{item.unit.name}}
        </td>
        <td v-if="hidden.discountPrice">
          {{item.discountPrice}}
        </td>
        <td v-if="hidden.account">
          {{item.account.code}}
          ({{item.account.name}})
        </td>
        <td v-if="hidden.contacts">
          <div v-for="(child,idx) in item.contacts" :key="child.id">
            {{idx+1}}. {{child.contact.code}} ({{child.contact.name}})
            <div v-if="child.contact.branch" class="text-info">
              <small>สาขา: {{child.contact.branch}}</small>
            </div>
          </div>
        </td>
      </tr>
    </template>

    <router-link
      slot="action"
      v-if="$auth.hasRole(`doc:${docType}:add`)"
      :to="toDetail(0)">
      <button
        type="button"
        class="btn btn-link text-success">
        เพิ่ม
      </button>
    </router-link>
  </sgv-table>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_DISCOUNT_PRICE } from './graph'

export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      discountPrices: [],
      detailView: `Doc${this.$form.capitalize(this.docType)}DetailDiscountPrice`,
      rKey: `Doc${this.$form.capitalize(this.docType)}ListDiscountPrice`,
      headers: [
        {text: 'ชื่อ', value: 'name', sort: true},
        {text: 'หน่วย', value: 'unit'},
        {text: 'ราคา', value: 'discountPrice'},
        {text: 'บัญชี', value: 'account'},
        {text: 'ลูกค้า', value: 'contacts'},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['name', 'unit', 'discountPrice', 'account', 'contacts'],
        column: null,
        search: null,
        toolbar: null,
      },
      rFields: ['filter', 'options'],
    }
  },
  computed: {
    toolbars () {
      const arr = [
        {value: 'filter', icon: 'cog', class: 'text-warning'},
      ]
      return arr
    },
    rowsPerPage () {
      return [
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
      ]
    }
  },
  apollo: {
    discountPrices: {
      query () {
        return LIST_DISCOUNT_PRICE(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          docType: this.docType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only',
    }
  },
  methods: {
    getFilter (v) {
      const filter = {
        ...v,
        params: {
          ...v.params
        }
      }

      return filter
    },
    toDetail (id) {
      return {
        name: this.detailView,
        params: {docConfigId: id}
      }
    },
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  }
}
</script>

<style lang="css" scoped>

</style>
