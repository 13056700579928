<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">
          {{title}}
          <router-link
            class="text-decoration-none text-secondary"
            v-if="$auth.hasRole(`doc:${docType}:config`)"
            :to="toDocConfig()">
            <small><fa icon="cog"></fa></small>
          </router-link>

          <UserManual
            class="ml-2"
            :templateType="templateType"
            :docType="docType">
          </UserManual>
        </h3>
      </div>

      <div class="dashhead-toolbar">
        <div class="dashhead-toolbar-item">
          <sgv-input-select
            :options="options"
            v-model="selected"
            select="text">
            <template slot-scope="option">
              {{option.text}}
            </template>
          </sgv-input-select>
        </div>
      </div>
    </div>

    <hr class="my-3">

    <div v-for="comp in options" :key="comp.text">
      <component
        v-if="selected === comp.text"
        :type="comp.type"
        :is="comp.value"
        :docType="docType"
        :templateType="templateType"
        :group="group">
      </component>
    </div>
  </div>
</template>

<script>
import ListContact from './ListContact.vue'
import ListGroup from './ListGroup.vue'
import ListInventory from './ListInventory.vue'
import ListAccount from './ListAccount.vue'
import ListEvaluate from './ListEvaluate.vue'
import ListExpense from './ListExpense.vue'
import ListDiscountPrice from './ListDiscountPrice.vue'
import retainMixin from '@/mixins/retain-mixin'
import UserManual from '@/views/doc_core/components/UserManual'


export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.title}`
    }
  },
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      docConfigView : `Doc${this.$form.capitalize(this.docType)}ConfigList`,
      rKey: `Doc${this.$form.capitalize(this.docType)}List`,
      options: [
        {text: 'ลูกค้า', value: 'ListContact'},
        {text: 'ใบราคา', value: 'ListGroup'},
        {text: 'สินค้า', value: 'ListInventory', type: 'item'},
        {text: 'บริการ', value: 'ListInventory', type: 'service'},
        {text: 'ค่าใช้จ่าย', value: 'ListExpense', type: 'expense'},
        {text: 'ส่วนลด', value: 'ListDiscountPrice', type: 'discountPrice'},
        {text: 'บัญชี-สินค้า', value: 'ListAccount', type: 'accountItem'},
        {text: 'บัญชี-บริการ', value: 'ListAccount', type: 'accountService'},
        {text: 'ทดสอบ', value: 'ListEvaluate'}
      ],
      selected: 'ลูกค้า',
      rFields: ['selected']
    }
  },
  methods: {
    toDocConfig () {
      return {name: this.docConfigView}
    },
  },
  watch: {
    selected (value) {
      this.setRetaining()

      if (this.options.findIndex(v => v.text === value) === -1) {
        this.selected = this.options[0].text
      }
    }
  },
  components: {
    ListContact,
    ListGroup,
    UserManual,
    ListInventory,
    ListAccount,
    ListEvaluate,
    ListExpense,
    ListDiscountPrice
  }
}
</script>

<style lang="css" scoped>
</style>
