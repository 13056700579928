<template>
  <div class="">
    <div class="form-row">
      <sgv-input-select
        class="col-7"
        label="ประเภท"
        :options="inventoryTypes"
        v-model="selected"
        select="value">
        <template slot-scope="option">
          {{option.text}}
        </template>
      </sgv-input-select>

      <sgv-input-datetime
        class="col-5"
        label="วันที่"
        v-model="expiredAt"
        isNull>
      </sgv-input-datetime>
    </div>

    <div class="form-row">
      <ListEvaluateContactInput
        label="ลูกค้า"
        :docType="docType"
        :templateType="templateType"
        ref="contact"
        class="col-12 col-md-7"
        select="id"
        v-model="contactId"
        @change="contact = $event"
        :validations="[
          {text: 'required!', value: $v.contactId.$dirty && !$v.contactId.required}
        ]">
      </ListEvaluateContactInput>

      <ListEvaluateSalesCreditInput
        :disabled="!contactId"
        label="เครดิต (วัน)"
        :docType="docType"
        :templateType="templateType"
        class="col-12 col-md-5"
        select="salesCredit"
        :contactId="contactId"
        v-model="salesCredit">
      </ListEvaluateSalesCreditInput>
    </div>

    <div class="form-row">
      <sgv-input-text
        class="col-7"
        label="ชื่อสินค้า"
        v-model="search">
      </sgv-input-text>

      <sgv-input-number
        class="col-5"
        label="จำนวน"
        v-model="qty"
        :precision="2">
      </sgv-input-number>
    </div>

    <button
      class="btn btn-warning mb-3"
      @click="dataDetail">
      ค้นหา
    </button>

    <sgv-table
      headerless
      class="mt-3"
      :rKey="rKey"
      :items="inventoriesMapped"
      :headers="headers"
      :toolbars="toolbars"
      :filter.sync="filter"
      :options.sync="options"
      :rowsPerPage="rowsPerPage">

      <template slot-scope="{item, hidden}">
        <tr>
          <td v-if="hidden.name" class="pre-line">
            {{item.name}}
          </td>
          <td v-if="hidden.prices">
            <div v-for="price in item.prices" :key="price.id">
              <span v-if="price.qty > 0" class="text-warning">
                ({{price.qty | comma(-1)}}) {{price.unit.name}}
              </span>
              {{price.price | comma(-1)}}
              <span v-if="price.parent" class="text-info">
                ({{price.parent.name}})
              </span>
              <span v-if="price.expiredAt" class="text-danger">
                {{price.expiredAt | datetime}}
              </span>
              <span v-if="price.isDefault" class="text-success">
                --- ค่าเริ่มต้น
              </span>
            </div>
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import ListEvaluateContactInput from './ListEvaluateContactInput'
import ListEvaluateSalesCreditInput from './ListEvaluateSalesCreditInput'
import {
  EVALUATE_INVENTORY,
} from './graph'

export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}ListEvaluate`,
      headers: [
        {text: 'ชื่อ', value: 'name'},
        {text: 'ราคา (บาท)', value: 'prices'},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['name', 'prices'],
        column: null,
        search: null,
        toolbar: null,
      },
      rFields: ['filter', 'options', 'expiredAt', 'selected'],
      contactId: null,
      contact: null,
      expiredAt: null,
      qty: 0,
      salesCredit: 0,
      search: '',
      inventories: [],
      inventoryTypes: [
        {text: 'สินค้า', value: 'item'},
        {text: 'บริการ', value: 'service'},
      ],
      selected: 'item'
    }
  },
  validations: {
    contactId: { required }
  },
  computed: {
    inventoriesMapped () {
      return this.inventories.reduce((t,v) => {
        const inv = t.find(x => x.name === v.name)

        if (!inv) {
          t.push({
            id: v.id,
            name: v.name,
            prices: [{
              id: v.id,
              price: v.salesPrice,
              parent: v.parent,
              isDefault: v.isDefault,
              unit: v.unit,
              qty: v.qty,
              expiredAt: v.expiredAt
            }]
          })
          return t
        }

        inv.prices.push({
          id: v.id,
          price: v.salesPrice,
          parent: v.parent,
          isDefault: v.isDefault,
          unit: v.unit,
          qty: v.qty,
          expiredAt: v.expiredAt
        })

        return t
      }, [])
    },
    toolbars () {
      const arr = [
        {value: 'filter', icon: 'cog', class: 'text-warning'},
      ]
      return arr
    },
    rowsPerPage () {
      return [
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
      ]
    }
  },
  methods: {
    dataDetail () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$apollo.query({
        query: EVALUATE_INVENTORY(this.templateType),
        variables: {
          docType: this.docType,
          type: this.selected,
          contactId: this.contactId,
          qty: this.qty,
          expiredAt: this.expiredAt ? this.$date.format(this.expiredAt).raw : null,
          q: this.getFilter(this.filter)
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.inventories = res.data?.items
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
      .finally(() => {
        this.isLoaded = true
      })
    },
    getFilter (v) {
      const filter = {
        ...v,
        params: {
          ...v.params,
          search: this.search
        }
      }

      return filter
    },
  },
  watch: {
    expiredAt: 'setRetaining',
    selected: 'setRetaining'
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    ListEvaluateContactInput,
    ListEvaluateSalesCreditInput
  }
}
</script>

<style lang="css" scoped>

</style>
