<template>
  <sgv-input-select
    :options="items"
    @input="emitInput"
    :validations="validations"
    v-bind="$attrs"
    :value="value"
    select="salesCredit">
    <template slot-scope="option">
      {{option.salesCredit}}
    </template>
  </sgv-input-select>
</template>

<script>
import { EVALUATE_SALES_CREDIT } from './graph'

export default {
  name: 'ListEvaluateSalesCreditInput',
  props: {
    contactId: {
      type: Number,
      required: false
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    value: {
      type: [Object, String, Number],
      required: false
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    },
  },
  data () {
    return {
      items: []
    }
  },
  apollo: {
    items: {
      query () {
        return EVALUATE_SALES_CREDIT(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          contactId: this.contactId,
        }
      },
      fetchPolicy: 'no-cache',
      skip () {
        if (!this.contactId) {
          this.emitInput(0)
          return true
        }
      },
      result (res) {
        const found = res.data.items.find(v => v.isDefault)
        if (found) this.emitInput(found.salesCredit)
        else this.emitInput(0)
      }
    }
  },
  methods: {
    emitInput (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
